import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getInstallerDescription } from "../../actions/download/actions";
import { INSTALLER_CODE } from "../../common/constants";
import { dictionaryGet } from "../../common/functions";
import { RenderBIHistory, RenderQlikHistoryContent } from "./functions/renderQlikFunctions";

interface Installer {
  BI: any;
  QLIK: any;
}

export function DownloadQlikHistory() {
  const current = useSelector((state: AppState) => state.user.current);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [installers, setInstallers] = useState<Installer | null>(null);
  const dictionary = useSelector((state: AppState) => state.dictionary);

  useEffect(() => {
    let installerObj: any = {};
    if (current.selectedCompany !== null) {
      let idCompany = current.selectedCompany.company.id;
      thunkDispatch(
        getInstallerDescription(INSTALLER_CODE.QLIK, idCompany)
      ).then((res: any) => {
        installerObj.QLIK = JSON.parse(res.data);
        setInstallers(installerObj);
      });
    }
  }, [current.selectedCompany]);

  return (
    <div className="qlik-history">
     
      <div className="card-container dashboard-claim">
        <div className="card-body">
          <h4 className="simple-kpi-head">
            {dictionaryGet(
              dictionary,
              "global.console.download.qlik_versions_disclaimer_1"
            )}
          </h4>
          <div>
          <RenderQlikHistoryContent
            installers={installers}
            dictionary={dictionary}
            current={current}
            thunkDispatch={thunkDispatch}
          />
          </div>
          <h4 className="simple-kpi-head">
            {dictionaryGet(
              dictionary,
              "global.console.download.qlik_versions_disclaimer_2"
            )}
          </h4>
          <div>
          <RenderBIHistory
            dictionary={dictionary}
            current={current}
            thunkDispatch={thunkDispatch}
          />
          </div>
        </div>
      </div>
    </div>
  );
}
